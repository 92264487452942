import React, { useState, useLayoutEffect, useRef } from "react";

import determineSwatchScope from "./helper/determineSwatchScope";
import { PopoverContext } from "../../App";
import { hexToBosch } from "./helper/hexToBosch";

/**
 * @name DefaultSwatch
 * @param {Number}  index             index of the swatch
 * @param {String}  swatch            swatch type [default|hovered|pressed|focused|disabled]
 * @param {String}  backgroundType    current background
 * @param {String}  schemeType        current scheme
 * @param {String}  mode              current color mode [light|dark]
 * @param {Boolean} isLastBackground  if true, current background is the last one of the selected
 * @returns
 * one swatch with given background and color
 * when clicked on the background or color representation the content of the 
 * popover will be update
 * the popover for the clicked swatch part will be shown
 */
const DefaultSwatch = ({ index, swatch, backgroundType, schemeType, mode, isLastBackground }) => {
  let swatchComboFill;
  let swatchComboFront;


  // determining swatch type
  switch (swatch) {
    case "disabled":
      swatchComboFill = `--${schemeType}__disabled__fill__default`;
      swatchComboFront = `--${schemeType}__disabled__front__default`;
      break;
    case "focused":
      swatchComboFill = `--${schemeType}__focused__fill__default`;
      swatchComboFront = `--${schemeType}__focused__front__default`;
      break;
    default:
      swatchComboFill = `--${schemeType}__enabled__fill__${swatch}`;
      swatchComboFront = `--${schemeType}__enabled__front__${swatch}`;
  }

  const fillClass = `var(${swatchComboFill})`;
  const frontClass = `var(${swatchComboFront})`;

  const [currentFill, setCurrentFill] = useState(null);
  const [currentFront, setCurrentFront] = useState(null);

  const paletteRef = useRef(null);
  const fillRef = useRef(null);
  const frontRef = useRef(null);

  useLayoutEffect(() => {
    if (fillRef.current !== undefined && frontRef.current !== undefined) {
      requestAnimationFrame(() => {
        const currentRefFill = window.getComputedStyle(fillRef.current);
        const currentRefFront = window.getComputedStyle(frontRef.current);

        setCurrentFill(currentRefFill.getPropertyValue(swatchComboFill));
        setCurrentFront(currentRefFront.getPropertyValue(swatchComboFront));
      });
    }
  }, [mode, swatchComboFill, swatchComboFront, fillRef, frontRef, isLastBackground]);

  return (
    <PopoverContext.Consumer>
      {({ updateState }) => {
        return (
          <>
            <div
              ref={paletteRef}
              className={`palette ${swatch === "focused" ? "-focused" : ""}`}
            >
              <div
                onClick={(event) => {
                  event.stopPropagation();

                  updateState({
                    color: currentFill,
                    swatch: swatchComboFill,
                    rgbToBosch: hexToBosch(currentFill)[0],
                    background: backgroundType,
                    target: fillRef,
                    fillRef,
                    frontRef,
                    mode,
                    position: isLastBackground ? 'right-bottom' : 'left-bottom',
                  });
                }}
                ref={fillRef}
                key={index}
                className={`colorFill colorFill-${swatch} ${
                  currentFill === "transparent" ? "-transparent" : ""
                }`}
                style={{
                  background: fillClass,
                }}
              ></div>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  updateState({
                    color: currentFront,
                    swatch: swatchComboFront,
                    rgbToBosch: hexToBosch(currentFront)[0],
                    target: frontRef,
                    fillRef,
                    frontRef,
                    position: isLastBackground ? 'right-bottom' : 'left-bottom',
                  });
                }}
                ref={frontRef}
                className="colorFront"
                style={{
                  background: frontClass,
                }}
              ></div>
              <div className="swatch-label-container -size-s">
                <div className="swatch-label">fill:</div>
                <div className="highlight">{hexToBosch(currentFill)}</div>
              </div>
              <div className="swatch-label-container -size-s">
                <div className="swatch-label">front:</div>
                <div className="highlight">{hexToBosch(currentFront)}</div>
              </div>
            </div>
          </>
        );
      }}
    </PopoverContext.Consumer>
  );
};

// render all swatches
const Swatch = ({ backgroundType, schemeType, mode, isLastBackground }) => {
  const swatches = determineSwatchScope(schemeType);

  return swatches.map((swatch, index) => (
    <DefaultSwatch
      key={index}
      index={index}
      backgroundType={backgroundType}
      schemeType={schemeType}
      swatch={swatch}
      mode={mode}
      isLastBackground={isLastBackground}
    />
  ));
};

export default Swatch;
