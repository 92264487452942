const isSameDomain = (styleSheet) => {
  // Internal style blocks won't have an href value
  if (!styleSheet.href) {
    return true;
  }
  return styleSheet.href.indexOf(window.location.origin) === 0;
};

/**
 * 
 * @param {string} rule   css rule to check
 * @returns 
 * true if rule is style-rule (1) - for more info: https://developer.mozilla.org/de/docs/Web/API/CSSRule
 * false if rule is not a style rule
 */
const isStyleRule = (rule) => rule.type === 1;

/**
 * @name colorAlphabet
 * @returns array of arrays with color-name & color-value
 * @description
 * (A) - get stylesheets of site and check if same domain
 * (B) - check if rule is a style rule
 * (C) - filter for all css custom properties, afterwards filter out all non-color css custom properties
 */
// eslint-disable-next-line no-unused-vars
const colorAlphabet = () =>
// (A)
  [...document.styleSheets].filter(isSameDomain).reduce(
    (finalArr, sheet) =>
      finalArr.concat(
        // (B)
        [...sheet.cssRules].filter(isStyleRule).reduce((propValArr, rule) => {
          const props = [...rule.style]
            .map((propName) => [
              propName.trim(),
              rule.style.getPropertyValue(propName).trim(),
            ])
            // (C)
            .filter(([propName]) => propName.indexOf("--") === 0)
            .filter(([propName]) => propName.indexOf("--bosch") === 0)
            .filter(([propName]) => propName.indexOf("--boschicon") !== 0)
            .filter(([propName]) => propName.indexOf("--bosch-ui") !== 0)
            .filter(([propName]) => propName.indexOf("--bosch-ic") !== 0)
            .filter(([propName]) => propName.indexOf("--shadow") !== 0);

          return [...propValArr, ...props];
        }, [])
      ),
    []
  );

const originalArray = [...colorAlphabet()];

// add transparent as a former key-value pair to list
originalArray.push(["transparent", "transparent"]);

/**
 * create array of objects with key-value pairs for color-name and color-value
 */
const orderedArray = originalArray.map(function (x) {
  return {
    name: x[0],
    color: x[1],
  };
});

/**
 * 
 * @param {Array} arr   array of ordered color-name and color-value pairs
 * @param {String} key  name of the key to filter on
 * @returns array with unique objects of color-name & color-value
 */
function getNoDuplicates(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

const cleanedUpArray = getNoDuplicates(orderedArray, "name");

export default cleanedUpArray;
