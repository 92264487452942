import React from "react";

import determineSchemeScope from "./helper/determineSchemeScope";
import "./Legend.scss";

/**
 * @name Legend
 * @param {Array} schemesSelected   array of selected schemes
 * @returns
 * renders each selected scheme
 * component will be the first column before the background columns
 */
const Legend = ({ schemesSelected }) => {
  return (
    <div className="legend">
      <div className="title"></div>
      <hr className="a-divider -top" />
      <div className="details highlight -size-s">color scheme</div>
      <hr className="a-divider -bottom" />
      {schemesSelected
        .sort((a, b) => a.index - b.index)
        .map(({ type: schemeType }, index) => {
          const scopes = determineSchemeScope(schemeType);

          return (
            <React.Fragment key={schemeType}>
              <div className="scheme">
                {scopes.map((scope, index) => (
                  <React.Fragment key={scope}>
                    {index === 0 ? (
                      <div key={scope} className="scheme-type -size-s">
                        <div className="scheme-name highlight -size-s">
                          {schemeType}
                        </div>
                        <div className="scheme-state -size-s">{scope}</div>
                      </div>
                    ) : (
                      <div key={scope} className="scheme-state -size-s">{scope}</div>
                    )}
                  </React.Fragment>
                ))}
              </div>
              {index < schemesSelected.length - 1 && (
                <hr className="a-divider" key={`${schemeType}-divider`} />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default Legend;
