import { useEffect, useRef, useState } from "react";

import { ancestors } from "./HelperPopover";
import { hexToHSL } from "./HelperPopover";
import { hexToRGB } from "./HelperPopover";
import CopyToClipboard from "./CopyToClipboard";

import "./Popover.scss";

/**
 * @name Popover
 * @param {String}    currentColor    current color of the selected swatch
 * @param {String}    currentSwatch   current swatch
 * @param {String}    rgbToBosch      bosch color value
 * @param {String}    backgroundType  background type
 * @param {Object}    target          clicked swatch element
 * @param {Function}  onClose         callback function to close popover
 * @param {Object}    anchor          element where the popover gets attached to
 * @param {String}    position        arrow position of the popover
 * @param {Boolean}   isBackground    if true, current swatch is a background swatch
 * @returns
 * popover with clicked swatch values
 */
const Popover = ({
  currentColor,
  currentSwatch,
  rgbToBosch,
  backgroundType,
  target,
  onClose,
  anchor,
  position,
  isBackground
}) => {
  const containerPopover = useRef(null);
  const [popoverComponent, setPopoverComponent] = useState(null);

  // initialize FROK popover component
  useEffect(() => {
    if (containerPopover.current) {
      setPopoverComponent(
        new window.boschFrontendKit.Popover(containerPopover.current)
      );
    }
  }, [containerPopover]);

  /** parameterize the FROK popover when target & popover is given
   * set arrow position
   * attach to clicked box
   * add event listener for close button of popover
   */
  useEffect(() => {
    if (popoverComponent && target) {
      popoverComponent.setPosition(position);
      popoverComponent.attach(target.current, anchor.current);
      popoverComponent.show();
      popoverComponent.addEventListener("closeButtonClicked", () => {
        popoverComponent.hide();
        onClose();
      });
    }
  }, [popoverComponent, target, onClose, anchor, position]);

  /**
   * add click outside event listener for closing popover
   */
  useEffect(() => {
    let clickListener;

    if (containerPopover) {
      clickListener = (event) => {
        if (!ancestors(event.target).includes(containerPopover.current)) {
          if (popoverComponent) {
            popoverComponent.hide();
            onClose();
          }
        }
      };
      document.addEventListener("click", clickListener);
    }

    // remove eventListener when unmounted
    return function cleanup() {
      if (popoverComponent) {
        popoverComponent.hide();
      }
      document.removeEventListener("click", clickListener);
    };
  }, [popoverComponent, onClose]);

  const hexValuePopover = currentColor;
  const RGBValuePopover = hexValuePopover ? hexToRGB(hexValuePopover) : false;
  const HSLValuePopover = hexValuePopover ? hexToHSL(hexValuePopover) : false;
  const boschValuePopover = `var(--${rgbToBosch})`;

  return (
    <div
      ref={containerPopover}
      className="m-popover -close-button"
      aria-label="Popover"
    >
      <div className="a-box -floating">
        <div className="m-popover__content">
          <div className="m-popover__head popover__head">
            {currentColor && (
              <>
                <div
                  style={{ backgroundColor: currentColor }}
                  className={`popover__head__square ${
                    currentColor === "transparent" ? "-transparent" : ""
                  }`}
                ></div>
                <div className="popover__head__info">
                  <div className="-size-m highlight">
                    {rgbToBosch.replace("-", " ")}
                  </div>
                  <div className="-size-s highlight">
                    {isBackground ? (
                      `${currentSwatch.replace("-", "")} background`
                    ) : (
                      currentSwatch.replace("--", "").replaceAll("__", " / ")
                    )}

                  </div>
                  {!isBackground && <div className="-size-s">on {backgroundType} background</div>}
                </div>
              </>
            )}
            <button
              type="button"
              className="a-button a-button--integrated -without-label"
              data-frok-action="close"
              aria-label="close popover"
            >
              <i
                className="a-icon a-button__icon ui-ic-close"
                title="close"
              ></i>
            </button>
          </div>
          <div className="popover__valueToCopy">
            <div className="popover__valueToCopy__type -size-s">HEX: </div>
            <div className="popover__valueToCopy__value">{hexValuePopover}</div>
            <div className="popover__valueToCopy__copyButton">
              <CopyToClipboard copiedValue={hexValuePopover} />
            </div>
          </div>
          <div className="popover__valueToCopy">
            <div className="popover__valueToCopy__type -size-s">RGB: </div>
            <div className="popover__valueToCopy__value">{RGBValuePopover}</div>
            <div className="popover__valueToCopy__copyButton">
              <CopyToClipboard copiedValue={RGBValuePopover} />
            </div>
          </div>
          <div className="popover__valueToCopy">
            <div className="popover__valueToCopy__type -size-s">HSL: </div>
            <div className="popover__valueToCopy__value">{HSLValuePopover}</div>
            <div className="popover__valueToCopy__copyButton">
              <CopyToClipboard copiedValue={HSLValuePopover} />
            </div>
          </div>
          <hr />
          <div className="popover__valueToCopy">
            <div className="popover__valueToCopy__type -size-s">var: </div>
            <div className="popover__valueToCopy__value -size-s highlight">
              {boschValuePopover}
            </div>
            <div className="popover__valueToCopy__copyButton">
              <CopyToClipboard copiedValue={boschValuePopover} />
            </div>
          </div>
          <div className="popover__valueToCopy">
            <div className="popover__valueToCopy__type -size-s">class: </div>
            <div className="popover__valueToCopy__value -size-s highlight">
              {currentSwatch}
            </div>
            <div className="popover__valueToCopy__copyButton">
              <CopyToClipboard copiedValue={currentSwatch} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popover;
