import Tooltip from "./Tooltip";

/**
 * @name CopyToClipboard
 * @param {String} copiedValue  value which will be put into clipboard
 * @returns
 * button to copy given value of the selected swatch into clipboard
 */
const CopyToClipboard = ({ copiedValue }) => {
  // On click copy the value in the clipboard and log it.
  const handleClick = () => {
    navigator.clipboard.writeText(copiedValue).then(
      () => {
        console.log("You copied the value", copiedValue);
      },
      (err) => {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  return (
    <>
      <button
        type="button"
        className="a-button a-button--integrated -without-label"
        aria-label="click and copy value"
        onClick={() => handleClick(copiedValue)}
      >
        <i className="a-icon a-button__icon boschicon-bosch-ic-copy"></i>
      </button>
      <Tooltip />
    </>
  );
};

export default CopyToClipboard;
