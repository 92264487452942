/**
 * @name Tooltip
 * @returns
 * tooltip for popover copy to clipboard function
 */
const Tooltip = () => {
  return (
    <span className="a-tooltip" role="tooltip">
      Copy to clipboard
    </span>
  );
};

export default Tooltip;
