import { useState } from "react";

/**
 *
 * @param {Array}     schemes             array of possible schemes
 * @param {Array}     schemesSelected     array of selected schemes
 * @param {Function}  setSchemesSelected  callback function to set schemes
 */
const SchemeSelection = ({ schemes, schemesSelected, setSchemesSelected }) => {
  // state to keep track of accordion state
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  // select all schemes
  const selectAll = () => {
    setSchemesSelected([
      ...schemes.map((scheme) => {
        return scheme;
      }),
    ]);
  };

  // deselect all schemes by emptying the array
  const deselectAll = () => {
    setSchemesSelected([]);
  };

  // dynamic wording for selected schemes
  const numberColorSchemesSelected =
    schemesSelected.length <= 1
      ? `scheme (${schemesSelected.length})`
      : `schemes (${schemesSelected.length})`;

  // rendering function for schemes
  const renderSchemes = (scheme, schemesSelected) => {
    const { type, index } = scheme;

    const selected = !!schemesSelected.find((item) => item.type === type);

    return (
      <div
        className={`a-chip ${selected ? "-selected" : ""}`}
        role="button"
        aria-labelledby={`chip-${type}`}
        key={index}
        onClick={() => {
          if (!selected) {
            setSchemesSelected([...schemesSelected, scheme]);
          } else {
            setSchemesSelected(
              schemesSelected.filter(
                (schemeSelected) => schemeSelected.type !== type
              )
            );
          }
        }}
      >
        <span id={`chip-${type}`} className="a-chip__label">
          {type}
        </span>
      </div>
    );
  };

  // rendering all possible color-schemes as chips to select & deselect
  return (
    <div
      className={`filters-wrapper -secondary ${isAccordionOpen ? "-open" : ""}`}
    >
      <button
        className="a-button a-button--integrated filters-wrapper__top"
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
      >
        <i className="a-icon a-button__icon boschicon-bosch-ic-colors"></i>
        <div className="a-button__label">
          Select {numberColorSchemesSelected}
        </div>

        <i className="a-icon a-button__icon boschicon-bosch-ic-down filters-wrapper__toggle"></i>
      </button>
      <div className="filters-wrapper__bottom">
        <div className="filters-wrapper__label highlight -size-s">Standard</div>
        <div className="filters-wrapper__category">
          {/* filtering for standard schemes */}
          {schemes
            .filter((scheme) => scheme.group === "standard")
            .map((scheme) => renderSchemes(scheme, schemesSelected))}
        </div>
        <div className="filters-wrapper__label highlight -size-s">Signal</div>
        <div className="filters-wrapper__category">
          {/* filtering for signal schemes */}
          {schemes
            .filter((scheme) => scheme.group === "signal")
            .map((scheme) => renderSchemes(scheme, schemesSelected))}
        </div>
        <div className="filters-wrapper__label highlight -size-s">
          Highlight
        </div>
        <div className="filters-wrapper__category">
          {/* filtering for highlight schemes */}
          {schemes
            .filter((scheme) => scheme.group === "highlight")
            .map((scheme) => renderSchemes(scheme, schemesSelected))}
        </div>

        <div className="filters-wrapper__buttons">
          <button
            onClick={selectAll}
            type="button"
            className="a-button a-button--integrated"
          >
            <i className="a-icon a-button__icon boschicon-bosch-ic-clear-all"></i>
            <div className="a-button__label">Select all</div>
          </button>
          <button
            onClick={deselectAll}
            type="button"
            className="a-button a-button--integrated"
          >
            <i className="a-icon a-button__icon boschicon-bosch-ic-close-all"></i>
            <div className="a-button__label">Deselect all</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SchemeSelection;
