import React, { useState, useEffect, createContext, useRef } from "react";

import "./App.scss";

import ModeSelection from "./components/ModeSelection/ModeSelection";
import BackgroundSelection from "./components/BackgroundSelection/BackgroundSelection";
import SchemeSelection from "./components/SchemeSelection/SchemeSelection";
import Legend from "./components/Palette/Legend";
import Background from "./components/Palette/Background";
import Popover from "./components/Popover/Popover";

// import schemes
import { default as schemes } from './components/SchemeSelection/schemes.json';

// context for popover content
export const PopoverContext = createContext({
  updateState: () => {}
});

// available color modes & backgrounds
const modes = ["light", "dark"];
const backgrounds = ["primary", "secondary", "contrast", "floating"];

// setup of initial state of the app
function App() {
  const [mode, setMode] = useState("light");
  const [backgroundsSelected, setBackgroundsSelected] = useState([
    {
      type: "primary",
      index: 0,
    },
    {
      type: "secondary",
      index: 1
    }
  ]);
  const [schemesSelected, setSchemesSelected] = useState([
    schemes[4],
    schemes[5]
  ]);

  /** 
   * hooks for update the content of the popover
   * 
  */
  const [currentColor, setCurrentColor] = useState(null);
  const [currentSwatch, setCurrentSwatch] = useState(null);
  const [currentRgbToBosch, setCurrentRgbToBosch] = useState(null);
  const [currentBackground, setCurrentBackground] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [isBackgroundSwatch, setIsBackgroundSwatch] = useState(false);
  const [fillRef, setFillRef] = useState(null);
  const [frontRef, setFrontRef] = useState(null);
  const [position, setPosition] = useState('left-bottom');

  /**
   * @name resetPopoverState
   * @description
   * resets the state of the popover to initial state
   */
  const resetPopoverState = () => {
    setCurrentColor(null);
    setCurrentSwatch(null);
    setCurrentRgbToBosch(null);
    setCurrentBackground(null);
    setCurrentTarget(null);
    setFillRef(null);
    setFrontRef(null);
    setPosition('left-bottom');
    setIsBackgroundSwatch(false);
  };

  /**
   * @name initialContext
   * @description
   * initial context state for the context provider
   * will include updateState function to be called on click of a swatch
   */
  const initialContext = {
    updateState: ({
      color,
      swatch,
      rgbToBosch,
      background,
      target,
      fillRef,
      frontRef,
      position,
      isBackground
    }) => {
      setCurrentColor(color);
      setCurrentSwatch(swatch);
      setCurrentRgbToBosch(rgbToBosch);
      setCurrentBackground(background);
      setCurrentTarget(target);
      setFillRef(fillRef);
      setFrontRef(frontRef);
      setPosition(position);
      setIsBackgroundSwatch(isBackground);

    },
  };

  // add dark mode to body tag
  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body")[0];

    if (mode === "dark") {
      bodyElement.classList.add("-dark-mode");
    } else {
      bodyElement.classList.remove("-dark-mode");
    }
  }, [mode]);

  const appRef = useRef(null);

  /**
   * Layout of the app
   * (A) - ColorMode Selection
   * (B) - Background Selection
   * (C) - Scheme Selection
   * (D) - Schemes
   * (E) - Popover
   */
  return (
    <div ref={appRef} className={`App -primary`}>
      <div className="color-picker__header -contrast">
        <i className="a-icon boschicon-bosch-ic-colors"></i>
        <span className="highlight -size-m">Bosch Color Schemes 1.0</span>
        <a
          className="-size-s"
          href="https://dds-color-picker.ui.bosch.tech/"
          target="_self"
        >
          <span>Switch to Bosch Color Scheme v2</span>
        </a>
      </div>
      <PopoverContext.Provider value={initialContext}>
        {/* (A) */}
        <ModeSelection currentMode={mode} modes={modes} setMode={setMode} />
        {/* (B) */}
        <BackgroundSelection
          backgrounds={backgrounds}
          backgroundsSelected={backgroundsSelected}
          setBackgroundsSelected={setBackgroundsSelected}
        />
        {/* (C) */}
        <SchemeSelection
          schemes={schemes}
          schemesSelected={schemesSelected}
          setSchemesSelected={setSchemesSelected}
        />
        {/* (D) */}
        {backgroundsSelected.length === 0 ? (
          <div className="default-message">
            <p>Please select at least one background</p>
          </div>
        ) : schemesSelected.length === 0 ? (
          <div className="default-message">
            <p>Please select at least one color scheme</p>
          </div>
        ) : (
          <React.Fragment>
            <div className="palettes" onScroll={resetPopoverState}>
              <Legend schemesSelected={schemesSelected} />
              {backgroundsSelected
                .sort((a, b) => a.index - b.index)
                .map(({ type }, index) => {
                  // determine if background is the last one and more than one is active
                  const isLastBackground = ((backgroundsSelected.length - 1) === index && backgroundsSelected.length > 1) ? true : false;

                  return (<React.Fragment key={type}>
                    <Background
                      key={type}
                      type={type}
                      mode={mode}
                      schemesSelected={schemesSelected}
                      isLastBackground={isLastBackground}
                    />
                  </React.Fragment>)
                })}
            </div>
          </React.Fragment>
        )}
        {/* (E) */}
        <Popover
          currentColor={currentColor}
          currentSwatch={currentSwatch}
          rgbToBosch={currentRgbToBosch}
          backgroundType={currentBackground}
          target={currentTarget}
          onClose={resetPopoverState}
          anchor={appRef}
          fillRef={fillRef}
          frontRef={frontRef}
          position={position}
          isBackground={isBackgroundSwatch}
        />
      </PopoverContext.Provider>
    </div>
  );
}

export default App;
