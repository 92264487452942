/**
 * @name determineSchemeScope
 * @param {String} type scheme to determine which scope is available
 * @returns
 * array of scheme scope
 */
const determineSchemeScope = (type) => {
  let legends;

  switch (type) {
    case "minor-signal-neutral":
    case "minor-signal-error":
    case "minor-signal-warning":
    case "minor-signal-success":
    case "major-signal-neutral":
    case "major-signal-error":
    case "major-signal-warning":
    case "major-signal-success":
    case "minor-highlight-purple":
    case "minor-highlight-blue":
    case "minor-highlight-turquoise":
    case "minor-highlight-green":
    case "major-highlight-purple":
    case "major-highlight-blue":
    case "major-highlight-turquoise":
    case "major-highlight-green":
      legends = ["enabled"];
      break;
    case "small":
    case "integrated":
    case "minor-accent":
    case "major-accent":
      legends = ["enabled", "disabled"];
      break;
    default:
      legends = ["enabled", "focused", "disabled"];
  }

  return legends;
};

export default determineSchemeScope;