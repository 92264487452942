import { useState } from "react";

/**
 *
 * @param {Array}     backgrounds             array of possible backgrounds [primary|secondary|floating|contrast]
 * @param {Array}     backgroundSelected      array of selected backgrounds
 * @param {Function}  setBackgroundsSelected  callback function to set backgrounds
 */
const BackgroundSelection = ({
  backgrounds,
  backgroundsSelected,
  setBackgroundsSelected,
}) => {
  // state to keep track of accordion state
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  // select all backgrounds
  const selectAll = () => {
    setBackgroundsSelected([
      ...backgrounds.map((background, index) => {
        return {
          type: background,
          index: index,
        };
      }),
    ]);
  };

  // deselect all backgrounds by emptying the array
  const deselectAll = () => {
    setBackgroundsSelected([]);
  };

  // dynamic wording for selected backgrounds
  const numberBackgroundsSelected =
    backgroundsSelected.length <= 1
      ? `background (${backgroundsSelected.length})`
      : `backgrounds (${backgroundsSelected.length})`;

  // rendering all possible backgrounds as chips to select & deselect
  return (
    <div
      className={`filters-wrapper -secondary ${isAccordionOpen ? "-open" : ""}`}
    >
      <button
        className="a-button a-button--integrated filters-wrapper__top"
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
      >
        <i className="a-icon a-button__icon boschicon-bosch-ic-screenshot-frame"></i>
        <div className="a-button__label">
          Select {numberBackgroundsSelected}
        </div>
        <i className="a-icon a-button__icon boschicon-bosch-ic-down filters-wrapper__toggle"></i>
      </button>
      <div className="filters-wrapper__bottom -no-label">
        <div className="filters-wrapper__category">
          {backgrounds.map((background, index) => {
            const selected = !!backgroundsSelected.find(
              (item) => item.type === background
            );

            return (
              <div
                className={`a-chip ${selected ? "-selected" : ""}`}
                role="button"
                aria-labelledby={`chip-${background}`}
                key={index}
                onClick={() => {
                  if (!selected) {
                    setBackgroundsSelected([
                      ...backgroundsSelected,
                      { type: background, index: index },
                    ]);
                  } else {
                    setBackgroundsSelected(
                      backgroundsSelected.filter(
                        (backgroundSelected) =>
                          backgroundSelected.type !== background
                      )
                    );
                  }
                }}
              >
                <span id={`chip-${background}`} className="a-chip__label">
                  {background}
                </span>
              </div>
            );
          })}
        </div>
        <div className="filters-wrapper__buttons">
          <button
            onClick={selectAll}
            type="button"
            className="a-button a-button--integrated"
          >
            <i className="a-icon a-button__icon boschicon-bosch-ic-clear-all"></i>
            <div className="a-button__label">Select all</div>
          </button>
          <button
            onClick={deselectAll}
            type="button"
            className="a-button a-button--integrated"
          >
            <i className="a-icon a-button__icon boschicon-bosch-ic-close-all"></i>
            <div className="a-button__label">Deselect all</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BackgroundSelection;
