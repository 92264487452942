import "./ModeSelection.scss";

/**
 * 
 * @param {String}    currentMode   current mode [light|dark]
 * @param {Array}     modes         array of possible modes
 * @param {Function}  setMode       callback function to set the global color mode
 */
const ModeSelection = ({ currentMode, modes, setMode }) => {
  return (
    <div
      className="ModeSelection -secondary"
    >
      <i
        className="a-icon boschicon-bosch-ic-sun-moon ModeSelection__icon"
        title="Lorem Ipsum"
      ></i>
      <div className="ModeSelection__text">Choose color mode</div>
      <div className="ModeSelection__modes">
        {modes.map((mode, index) => (
          <div className="a-radio-button" key={index}>
            <input
              type="radio"
              id={mode}
              name="color-mode"
              value={mode}
              onChange={(e) => setMode(e.target.value)}
              checked={currentMode === mode}
            />
            <label htmlFor={mode}>{mode} mode</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModeSelection;
