import React, { useRef } from "react";

import Swatch from "./Swatch";
import { PopoverContext } from "../../App";
import "./Background.scss";
import { hexToBosch } from "./helper/hexToBosch";

/**
 * @name Background
 * @param {String}  backgroundType        background type
 * @param {Array}   schemesSelected       current selected schemes
 * @param {String}  mode                  current color mode
 * @param {Boolean} isLastBackground      if true, current background is the last one of the selected
 * @returns
 * renders each selected scheme for the given background type
 */

const Background = ({ type: backgroundType, schemesSelected, mode, isLastBackground }) => {
  const backgroundRef = useRef(null);
  const backgroundWrapperRef = useRef(null);

  return (
    <PopoverContext.Consumer>
      {({ updateState }) => {
        return (
          <div className="background">
            <div className={`background-demonstrator -${backgroundType}`}></div>
            <div className={`background-wrapper -${backgroundType}`} ref={backgroundWrapperRef}>
              <div className="title -size-s"
                ref={backgroundRef}
                onClick={(event) => {
                  event.stopPropagation();
                  const computedStyle = window.getComputedStyle(backgroundWrapperRef.current)
                  const backgroundColor = computedStyle.getPropertyValue('--background');

                  updateState({
                    color: backgroundColor,
                    rgbToBosch: hexToBosch(backgroundColor)[0],
                    swatch: `-${backgroundType}`,
                    target: backgroundRef,
                    position: isLastBackground ? "right-bottom" : "left-bottom",
                    isBackground: true
                  });
                }}>{backgroundType} background <i class="a-icon ui-ic-alert-info" title="alert-info"></i></div>
            </div>
            <hr className="a-divider" />
            <div className={`background-wrapper -${backgroundType}`}>
              <div className="details -size-s highlight">
                <div>default</div>
                <div>hovered</div>
                <div>pressed</div>
              </div>
            </div>
            <hr className="a-divider" />
            {schemesSelected
              .sort((a, b) => a.index - b.index)
              .map(({ type: schemeType }, index) => {
                return (
                  <React.Fragment key={schemeType}>
                    <div className={`scheme -${backgroundType}`}>
                      <Swatch
                        isLastBackground={isLastBackground}
                        backgroundType={backgroundType}
                        schemeType={schemeType}
                        mode={mode}
                      />
                    </div>
                    {index < schemesSelected.length - 1 && (
                      <hr className="a-divider" />
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        );
      }}
    </PopoverContext.Consumer>

  );
};

export default Background;
